<template>
  <v-row justify="center">
    <v-overlay
      :z-index="zIndex"
      :opacity="opacity"
      :value="overlay"
      color="#f4f4f4"
    >
        <v-img src="../../assets/img/loader.gif" width="400"></v-img>
    </v-overlay>
  </v-row>
</template>

<script>
  export default {
    data: () => ({
      overlay: true,
      opacity: 1,
      zIndex: 0,
    }),
  }
</script>

<style scoped>
</style>
